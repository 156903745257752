import { __decorate } from "tslib";
import { Component, Inject, Vue } from 'vue-property-decorator';
import JobsList from '@/components/shared/JobsList.vue';
import ComparisonApi from '../../services/api/comparison.api';
let ComparisonPage = class ComparisonPage extends Vue {
    constructor() {
        super(...arguments);
        this.loading = false;
    }
    async showLogs(query) {
        await this.$router.push({
            name: 'backup-to-org-comparison.logs',
            query,
        });
    }
    async createComparison() {
        this.loading = true;
        const { projectName } = await ComparisonApi.createComparison(this.organizationId);
        await this.$router.push({
            name: 'backup-to-org-comparison.create',
            params: {
                projectName,
            },
        });
    }
};
__decorate([
    Inject()
], ComparisonPage.prototype, "organizationId", void 0);
ComparisonPage = __decorate([
    Component({
        components: { JobsList },
    })
], ComparisonPage);
export default ComparisonPage;
