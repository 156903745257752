import { __decorate } from "tslib";
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { formatDate } from '@/utils';
import JobsApi from '@/services/api/jobs.api';
let JobsList = class JobsList extends Vue {
    constructor() {
        super(...arguments);
        this.perPageLimits = [10, 25, 50];
        this.tableFields = [
            { key: 'index', label: '#', thStyle: { width: '10%' } },
            { key: 'startedDate', label: 'Started date', thStyle: { width: '30%' } },
            { key: 'completedDate', label: 'Completed date', thStyle: { width: '30%' } },
            { key: 'status', label: 'Status', thStyle: { width: '20%' } },
            { key: 'actions', label: 'Actions', thStyle: { width: '10%' } },
        ];
        this.pagination = {
            limit: 25,
            page: 1,
            total: 0,
        };
        this.jobs = [];
    }
    get localOffset() {
        return this.pagination.limit * (this.pagination.page - 1);
    }
    onPaginationChanger() {
        this.onPageChanged();
    }
    onRowSelected(rows) {
        return rows[0];
    }
    onShowLogs(job) {
        return {
            jobType: job.type,
            jobId: job.id?.toString(),
        };
    }
    onPageChanged() {
        this.$nextTick(() => this.fetchJobs());
    }
    mounted() {
        this.fetchJobs();
    }
    async abortJob(job) {
        try {
            const { message } = await JobsApi.abort(job.id);
            this.$toast.success(message);
            await this.fetchJobs();
        }
        catch (error) {
            this.$toast.error(error.message);
        }
    }
    async fetchJobs() {
        try {
            const result = await JobsApi.find(this.organizationId, this.jobTypes, this.jobStatuses, this.pagination.page, this.pagination.limit);
            this.pagination.total = result.numberRecords;
            this.jobs = result.jobs.map(job => ({
                ...job,
                startedDate: job.startedDate ? formatDate(+job.startedDate) : '',
                completedDate: job.completedDate ? formatDate(+job.completedDate) : '',
            }));
        }
        catch (error) {
            this.$toast.error(error.message);
        }
    }
};
__decorate([
    Prop()
], JobsList.prototype, "jobTypes", void 0);
__decorate([
    Prop({
        default: () => ['In Progress', 'Exception', 'Completed', 'Aborted'],
    })
], JobsList.prototype, "jobStatuses", void 0);
__decorate([
    Prop()
], JobsList.prototype, "organizationId", void 0);
__decorate([
    Watch('pagination.page', { deep: true })
], JobsList.prototype, "onPaginationChanger", null);
__decorate([
    Emit()
], JobsList.prototype, "onRowSelected", null);
__decorate([
    Emit()
], JobsList.prototype, "onShowLogs", null);
JobsList = __decorate([
    Component({})
], JobsList);
export default JobsList;
