import axios from '../axios-instance';
import { API_URL } from '../api-url/api-url.service';
export default class JobsApi {
    static async abort(id) {
        return axios.patch(`${API_URL}/jobs/${id}/abort`).then(({ data }) => data);
    }
    static async find(orgId, types, statuses, pageNumber, limit) {
        return axios
            .get(`${API_URL}/jobs`, {
            params: {
                limit,
                orgId,
                type: types ? types.join(',') : '',
                pageNumber,
                status: statuses ? statuses.join(',') : '',
            },
        })
            .then(({ data }) => data);
    }
}
